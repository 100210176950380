import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Icon, Image, Placeholder } from "semantic-ui-react";

import ButtonTertiary from "../../../components/button/tertiary/index.web";
import Link from "../../../components/link/index.web";
import { linkTo } from "../routes-links";
import Feedback from "./feedback.web";

const COMMUNITY_CONFIG = gql`
  query Community {
    community @client {
      conciergeEmail
      familiarName
    }
  }
`;

export default function Sidebar({
  loading,
  articleId,
  status,
  hasEditorAssigned,
  contentGuidelinesInitiallyExpanded,
  onHasUnread,
}) {
  const [contentGuidleinesExpanded, setContentGuidelinesExpanded] = useState(
    contentGuidelinesInitiallyExpanded
  );

  const {
    data: { community },
  } = useQuery(COMMUNITY_CONFIG);

  function toggleContentGuidlines() {
    setContentGuidelinesExpanded(!contentGuidleinesExpanded);
  }

  if (loading) {
    return (
      <div className="ie-card">
        <h4 className="ie-card-title">
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="medium" />
            </Placeholder.Header>
          </Placeholder>
        </h4>

        <div className="ie-card-content">
          <Placeholder>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="short" />
          </Placeholder>
        </div>
      </div>
    );
  }

  return (
    <>
      {status === "NEEDS_REVISION" && (
        <div className="ie-card">
          <h4 className="ie-card-title">What&apos;s Next</h4>

          <div className="ie-card-content">
            <ul>
              <li>Review your editor&apos;s feedback</li>
              <li>Make sure you save your changes</li>
              <li>Resubmit for editor review</li>
            </ul>
          </div>
        </div>
      )}

      <div className="ie-card ie-card-collapsible">
        <ButtonTertiary
          className="ie-card-collapsible-header"
          onClick={toggleContentGuidlines}
        >
          <h4 className="ie-card-title">Content Guidelines</h4>
          <Icon className="angle-down" size="big" fitted />
        </ButtonTertiary>

        {contentGuidleinesExpanded && (
          <div className="ie-card-content">
            <p>
              Before beginning, review our guidelines on how to write great
              content.
            </p>

            <Image
              className="ie-card-image"
              src="https://storage.googleapis.com/cco-web-assets/publishing/guidelines-graphic.svg"
            />

            <p>
              <Link to={linkTo.guidelinesEdit()} className="ie-card-link">
                Review guidelines
              </Link>
            </p>
          </div>
        )}
      </div>

      {status === "DRAFT" || !hasEditorAssigned ? (
        <>
          <div className="ie-card">
            <h4 className="ie-card-title">Reminders</h4>

            <div className="ie-card-content">
              <ul>
                <li>Include a suggested headline</li>
                <li>Submit original text (not yet published elsewhere)</li>
                <li>Include links to any report, stats, or research</li>
              </ul>
            </div>
          </div>

          <div className="ie-message-card">
            <Icon className="ie-message-card-icon info-circle" />

            <div className="ie-message-card-content">
              <h4 className="ie-message-card-title">Need Assistance?</h4>
              <p>
                Email your{" "}
                <a href={`mailto:${community.conciergeEmail}`}>
                  {community.familiarName} Concierge
                </a>
              </p>
            </div>
          </div>
        </>
      ) : (
        <Feedback articleId={articleId} onHasUnread={onHasUnread} />
      )}
    </>
  );
}

Sidebar.propTypes = {
  loading: PropTypes.bool,
  articleId: PropTypes.string,
  status: PropTypes.string,
  hasEditorAssigned: PropTypes.bool,
  contentGuidelinesInitiallyExpanded: PropTypes.bool,
  onHasUnread: PropTypes.func,
};

Sidebar.defaultProps = {
  loading: false,
  articleId: "",
  status: "DRAFT",
  hasEditorAssigned: false,
  contentGuidelinesInitiallyExpanded: false,
  onHasUnread: () => {},
};
