import PropTypes from "prop-types";
import React, { useState } from "react";

import { queryProductAgreementStatus } from "../../graphql/product-agreement";
import ProductAgreementModal from "./modal.web";

export const ProductAgreementGateway = ({
  children,
  hasAccepted,
  history,
  loading,
}) => {
  const [accepted, setAccepted] = useState(false);

  const onSubmit = () => {
    setAccepted(true);
    history.push("/articles/edit");
  };
  if (loading || hasAccepted || accepted) {
    return children;
  }

  return <ProductAgreementModal onSubmit={onSubmit} />;
};

ProductAgreementGateway.propTypes = {
  children: PropTypes.node,
  hasAccepted: PropTypes.bool,
  loading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ProductAgreementGateway.defaultProps = {
  children: null,
  hasAccepted: false,
  loading: false,
};

export default queryProductAgreementStatus(ProductAgreementGateway);
